'use client';

import type {FC} from 'react';

import {Navbar} from '@/components/navbar';
import type {SiteSettingsQueryResult} from '@/sanity/lib/queries';
import {SITE_SETTINGS_QUERY} from '@/sanity/lib/queries';
import {useQuery} from '@/sanity/loader/useQuery';

import {navigationTypeFromSiteSettingsFragment} from './query';

type Props = {
	initial: SiteSettingsQueryResult;
};

export const NavbarDraft: FC<Props> = ({initial}) => {
	const {data: siteSettings} = useQuery<SiteSettingsQueryResult>(initial, SITE_SETTINGS_QUERY);

	if (!siteSettings) {
		return null;
	}

	return <Navbar navigation={navigationTypeFromSiteSettingsFragment(siteSettings)} />;
};

export default NavbarDraft;
