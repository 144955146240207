'use client';

import type {FC} from 'react';

import {Footer} from '@/components/footer';
import {SITE_SETTINGS_QUERY, type SiteSettingsQueryResult} from '@/sanity/lib/queries';
import {useQuery} from '@/sanity/loader/useQuery';

import {footerPropsFromSiteSettingsFragment} from './query';

interface Props {
	initial: SiteSettingsQueryResult;
}

export const FooterContainerDraft: FC<Props> = ({initial}) => {
	const {data: siteSettings} = useQuery<SiteSettingsQueryResult>(initial, SITE_SETTINGS_QUERY);

	if (!siteSettings) {
		return null;
	}

	return <Footer {...footerPropsFromSiteSettingsFragment(siteSettings)} />;
};

export default FooterContainerDraft;
