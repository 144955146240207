import type {NavigationType} from '@/components/navbar';
import type {SiteSettingsFragment} from '@/sanity/lib/queries';
import {resolveLinkHref} from '@/shared';

export function navigationTypeFromSiteSettingsFragment(
	siteSettings: SiteSettingsFragment,
): NavigationType {
	const navigation: NavigationType = [];

	if (!siteSettings.navigation) {
		return navigation;
	}

	for (const item of siteSettings.navigation) {
		if (item._type === 'link') {
			navigation.push({
				type: 'navlink',
				href: resolveLinkHref(item),
				title: item.title ?? '',
				targetBlank: item.targetBlank,
				_key: item._key,
				featured: item.featured,
			});
		} else if (item._type === 'dropdown') {
			navigation.push({
				type: 'dropdown',
				_key: item._key,
				title: item.title ?? '',
				links:
					item.links?.map((link) => ({
						href: resolveLinkHref(link),
						title: link.title ?? '',
						targetBlank: link.targetBlank,
						_key: link._key,
						featured: link.featured,
						type: 'navlink',
					})) ?? [],
			});
		}
	}

	return navigation;
}
