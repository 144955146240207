'use client';

import type {FC} from 'react';
import {useCallback, useEffect} from 'react';
import {useState} from 'react';
import clsx from 'clsx';
import {useRouter} from 'next/navigation';

import {Spinner} from '../spinner';

export const PreviewBar: FC = () => {
	const router = useRouter();
	const [isIframe, setIsIframe] = useState(false);
	const [isExitingPreview, setIsExitingPreview] = useState(false);

	const handleClose = useCallback(async () => {
		setIsExitingPreview(true);

		try {
			await fetch('/api/disable-draft');
			router.refresh();
			setIsExitingPreview(false);
		} catch (error) {
			setIsExitingPreview(false);
		}
	}, [router]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsIframe(window.self !== window.top);
		}
	}, []);

	if (isIframe) {
		return null;
	}

	return (
		<div
			className={clsx(
				'flex',
				'flex-wrap',
				'items-center',
				'text-sm',
				'gap-4',
				'p-4',
				'justify-center',
				'bg-yellow-400',
				'text-black',
			)}
		>
			<span>Du ser forhåndsvisning av upublisert innhold.</span>

			<button
				onClick={handleClose}
				className={clsx(
					'flex',
					'items-center',
					'gap-1',
					'underline',
					'decoration-2',
					'underline-offset-4',
					'hover:no-underline',
					'font-bold',
				)}
			>
				Avslutt forhåndsvisning
				{isExitingPreview && <Spinner title="avslutter" />}
			</button>
		</div>
	);
};
